<template>
  <div class="rewards-container">
    <Header :to="'lobby'" />
    <div v-if="!showSentMessage">
      <div class="mainContent gap-4 justify-items-center text-center">
        <div v-if="selectRewards">
          <div class="flex justify-between p-4">
            <div
              class="flex font-ZuumeBold text-nColorOrange text-4xl"
              v-if="freebieDetails && freebieDetails.length != 0"
            >
              <p>Rewards</p>
            </div>
          </div>
          <div>
            <div
              v-if="isLoading"
              class="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
            >
              <svg
                width="80"
                height="18"
                viewBox="0 0 120 30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
              >
                <circle cx="15" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                  <animate
                    attributeName="r"
                    from="9"
                    to="9"
                    begin="0s"
                    dur="0.8s"
                    values="9;15;9"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="0.5"
                    to="0.5"
                    begin="0s"
                    dur="0.8s"
                    values=".5;1;.5"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="105" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
            <div class="p-4 grid grid-cols-2 gap-4" v-if="freebieDetails">
              <div
                v-for="freebie in freebieDetails"
                :key="freebie"
                class="relative"
              >
                <div
                  class="border border-dotted border-nColorLightGray absolute top-0 left-0 w-full h-full bg-nColorDarkGray blurry-div z-10 rounded-xl flex items-center justify-center"
                  v-if="
                    freebie.freebies.details[0].expiry === 'expired' &&
                    freebie.claimed === false
                  "
                >
                  <span
                    class="text-white border border-dotted border-white px-4 rounded-xl bg-white bg-opacity-40"
                    >Expired</span
                  >
                </div>

                <div>
                  <div
                    class="absolute top-0 left-3"
                    v-if="freebie.claimed === true"
                  >
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/common/green_redeemed.webp"
                      alt="Redeemed Icon"
                      width="30"
                      height="42"
                    />
                  </div>
                  <div
                    class="h-44 bg-nColorVoucherBG p-2 rounded-xl border border-nColorVoucherBorder text-left grid gap-1 content-start"
                    @click.prevent="redeemVoucher(freebie)"
                  >
                    <img :src="freebie.freebies.details[0].logo" />
                    <p class="text-nColorYellow font-InterSemiBold text-sm">
                      {{ freebie.freebies.details[0].offer }}
                    </p>
                    <p
                      class="freebieDesc font-InterRegular text-nColorBGLightGray"
                    >
                      {{ freebie.freebies.details[0].description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="freebieDetails && freebieDetails.length === 0">
              <div class="flex justify-center">
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/gamer/empty_rewards.webp"
                  alt="Empty Rewards"
                  width="340"
                  height="533"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="emailPrompt" class="hidden">
          <div
            id="overlay-blur"
            class="absolute top-0 w-screen h-screen bg-nColorDarkGray blurry-div z-10"
          ></div>
          <div
            class="w-11/12 p-4 bg-nColorYellow rounded-xl absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-20 pb-10"
          >
            <div class="grid place-items-center justify-items-center">
              <p class="font-InterMedium text-black text-left text-sm">
                We'll send the voucher code to your email
              </p>
              <input
                type="text"
                name="email"
                ref="email"
                id="email"
                v-model="email"
                placeholder="Email ID"
                class="textInput mt-2"
                autofocus
                required
              />
            </div>
            <button
              :class="{ disabled: isSubmitLoading }"
              class="mt-4 w-72 bg-nColorOrange rounded-3xl text-nColorDarkGray font-ZuumeSemiBold text-3xl border border-nColorDarkGray shadow-black-right"
              @click.prevent="submitEmail"
              :disabled="isSubmitLoading"
            >
              <span v-if="isSubmitLoading">
                <svg
                  width="40"
                  height="30"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <p v-else>Submit</p>
            </button>
          </div>
        </div>
      </div>
      <div
        id="termsconditions"
        v-if="voucherDetails && voucherDetails.length > 0"
      >
        <div
          id="terms-conditions-blur"
          class="fixed top-0 w-screen h-screen bg-nColorDarkGray blurry-div z-10"
        ></div>
        <div
          id="terms-conditions-content"
          class="terms-conditions fixed top-0 w-screen h-screen z-20 overflow-scroll"
        >
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/assets/common/close.webp"
            alt="Close Icon"
            class="absolute top-4 right-4 z-20"
            width="40"
            height="40"
            @click.prevent="reloadPage()"
          />
          <div
            class="bg-black w-20 h-1 rounded-xl absolute -top-4 transform left-1/2 -translate-x-1/2"
          ></div>
          <div class="flex justify-center pt-4">
            <div
              class="w-full bg-black rounded-2xl p-4 mx-4 overflow-scroll no-scrollbar text-white pb-12"
            >
              <div class="flex items-center gap-2">
                <div>
                  <img
                    :src="voucherDetails[0].logo"
                    alt="Reward Icon"
                    class="w-32"
                  />
                </div>
                <div>
                  <p class="text-xl font-bold">
                    {{ voucherDetails[0].brand }}
                  </p>
                </div>
              </div>
              <div class="pt-4">
                <p class="text-xl">
                  {{ voucherDetails[0].offer }}
                </p>
                <p class="text-sm">
                  {{ voucherDetails[0].description }}
                </p>
              </div>
              <div
                class="bg-nColorBGDarkGray rounded-3xl px-4 py-2 mt-2"
                v-if="voucherDetails[0].voucher != 'No code needed'"
              >
                <div class="flex justify-between">
                  <p>{{ voucherDetails[0].voucher }}</p>
                  <div
                    class="bg-nColorYellow rounded-3xl px-2 py-1 text-black text-sm font-bold"
                    @click="copyVoucher"
                  >
                    COPY
                  </div>
                </div>
              </div>
              <div
                class="w-full font-InterMedium text-sm px-2 py-0.5 mt-1"
                v-if="voucherDetails[0].expiry != -1"
              >
                {{ voucherDetails[0].expiry }}
              </div>
              <div class="font-InterMedium text-sm mt-2">
                <p class="text-xl">Terms & Conditions</p>
                <div
                  class="flex gap-2"
                  v-for="item in voucherDetails[0].terms_conditions"
                  :key="item"
                >
                  <p class="font-InterMedium text-xl">•</p>
                  <p>{{ item }}</p>
                </div>
              </div>
            </div>
            <div
              class="w-full bg-nColorOrange rounded-t-3xl text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-1 fixed bottom-0 border-t border-nColorDarkGray"
              @click.prevent="redeemNow(voucherDetails[0].link)"
            >
              <p>Redeem now</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="relative px-8 pt-8">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/gamer/rewards_sent.webp"
          />
          <div class="flex justify-center">
            <div
              class="w-full bg-nColorOrange rounded-3xl text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-1 gap-2 border border-nColorDarkGray shadow-yellow-right"
              @click.prevent="goBackToLobby"
            >
              <p>Close</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";
import Header from "@/components/elements/Header.vue";

const toast = useToast();

export default {
  name: "Rewards",
  components: { Header },
  data() {
    return {
      businessCategory: this.$store.getters.businessCategory,
      voucher: null,
      email: null,
      redeemLoader: null,
      freebieDetails: null,
      redeemAll: false,
      redeemable: 0,
      hasChosenCount: 0,
      selectedVouchers: [],
      selectedExpiry: -1,
      selectedTermsConditions: [],
      showSentMessage: false,
      showImage: false,
      selectRewards: true,
      isSubmitLoading: false,
      selectedLink: null,
      selectedScheduleId: null,
      voucherDetails: [],
      tempVoucherDetails: [],
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    ApiService.get(apiResource.rewards.getDetails, {
      business_slug: this.$route.params.business,
    })
      .then((data) => {
        this.freebieDetails = data.data.data.rewards;
        this.email = data.data.data.user_email;
        this.isLoading = false;
      })
      .catch(() => {});
  },
  mounted() {},
  methods: {
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    toggleVoucher(slug) {
      const index = this.selectedVouchers.indexOf(slug);

      if (index !== -1) {
        this.selectedVouchers.splice(index, 1);
        this.hasChosenCount -= 1;
      } else {
        if (this.hasChosenCount === this.redeemable) {
          document
            .querySelector("#chosenLimitReached")
            .classList.remove("hidden");
        } else {
          this.selectedVouchers.push(slug);
          this.hasChosenCount += 1;
        }
      }
    },
    gotIt() {
      document.querySelector("#chosenLimitReached").classList.add("hidden");
    },
    sendCouponCode() {
      ApiService.post(apiResource.freebie.send, {
        business_slug: this.$route.params.business,
        schedule_id: this.selectedScheduleId,
        email: this.email,
        vouchers: [this.selectedVouchers[0]],
      })
        .then((data) => {
          this.selectedLink = null;
          this.selectedScheduleId = null;
          this.voucherDetails = this.tempVoucherDetails;
          this.tempVoucherDetails = [];
          this.voucherDetails[0].voucher = data.data.data.code;
        })
        .catch(() => {
          this.selectedLink = null;
          this.selectedScheduleId = null;
        });
    },
    openTermsConditions(expiry, termsConditions) {
      if (termsConditions != "-1") {
        this.selectedExpiry = expiry;
        this.selectedTermsConditions = termsConditions.split("|");
        document
          .querySelector("#terms-conditions-blur")
          .classList.remove("hidden");
        const termsConditionsContent = document.querySelector(
          "#terms-conditions-content"
        );
        termsConditionsContent.classList.remove("hidden");
        setTimeout(() => {
          termsConditionsContent.classList.add("show");
        }, 1);
      }
    },
    closeTermsConditions() {
      this.selectedExpiry = -1;
      this.selectedTermsConditions = [];
      document.querySelector("#terms-conditions-blur").classList.add("hidden");
      document
        .querySelector("#terms-conditions-content")
        .classList.add("hidden");
      document
        .querySelector("#terms-conditions-content")
        .classList.remove("show");
    },
    leaveAndGoToLobby() {
      document.getElementById("leavingPopUp").classList.remove("hidden");
    },
    closeRatingPopup() {
      document.getElementById("leavingPopUp").classList.add("hidden");
    },
    selectRating(rating) {
      for (var i = 1; i <= 5; i++) {
        document.getElementById(
          i + "star"
        ).src = require("@/assets/images/rating/" + i + ".svg");
      }
      for (i = 1; i <= rating; i++) {
        document.getElementById(
          i + "star"
        ).src = require("@/assets/images/rating/" + i + "-selected.svg");
      }
      ApiService.post(apiResource.freebie.saveFeedback, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
        rating: rating,
      })
        .then(() => {
          this.goBackToLobby();
        })
        .catch(() => {
          this.goBackToLobby();
        });
    },
    enterEmailForRewards() {
      this.selectRewards = false;
    },
    submitEmail() {
      if (!this.email) {
        toast.error("Please enter a valid email id", { timeout: 2000 });
      } else {
        document.querySelector("#emailPrompt").classList.add("hidden");
        this.sendCouponCode();
      }
    },
    redeemVoucher(freebie) {
      if (
        freebie.freebies.details[0].expiry != "expired" ||
        freebie.claimed === true
      ) {
        this.selectedLink = freebie.freebies.details[0].link;
        this.selectedScheduleId = freebie.schedule_id;
        this.selectedVouchers.push(freebie.freebies.details[0].slug);
        if (!this.email) {
          this.tempVoucherDetails = freebie.freebies.details;
          document.querySelector("#emailPrompt").classList.remove("hidden");
        } else if (freebie.claimed) {
          this.voucherDetails = freebie.freebies.details;
          this.voucherDetails[0].voucher = freebie.voucher;
        } else if (!freebie.claimed) {
          this.tempVoucherDetails = freebie.freebies.details;
          this.sendCouponCode();
        }
      }
    },
    reloadPage() {
      window.location.reload();
    },
    copyVoucher() {
      if (this.voucherDetails[0].voucher != "No code needed") {
        const textarea = document.createElement("textarea");
        textarea.value = this.voucherDetails[0].voucher;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.success("Voucher code copied to clipboard!", { timeout: 2000 });
      }
    },
    redeemNow(link) {
      this.copyVoucher();
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped lang="postcss">
.disabled {
  @apply bg-nColorBGDarkGray;
  pointer-events: none;
}
.rewards-container {
  height: 100dvh;
}
.sub-text {
  opacity: 0.5;
}
.textInput {
  @apply border border-black rounded-lg block appearance-none focus:outline-none bg-nColorYellow w-72 py-2.5 px-2 placeholder-black text-lg font-InterMedium;
}
.textInput::placeholder {
  @apply font-InterMedium text-nColorDarkGray text-lg tracking-normal;
}
.disabled {
  @apply bg-nColorBGDarkGray;
  pointer-events: none;
}
.blurry-div {
  background-color: rgba(35, 31, 32, 0.85);
}

.moving-image {
  animation: MyDiv 200ms ease-in;
  animation-direction: normal;
}
@keyframes MyDiv {
  0% {
    left: -140px;
    top: -80px;
  }
  100% {
    left: 0px;
    top: -80px;
  }
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.freebieDesc {
  font-size: 0.65rem;
}
</style>
